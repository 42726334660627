body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.content {
  width: 21cm;
  height: 29.7cm;
  overflow: hidden;
}

table.layout {
   border-spacing: 0.25cm 0;
  margin-left: 0.125cm;

  .top-row td {
    height: 1.07cm;
  }
  .row td {
    width: 3.81cm;
    height: 2.12cm;
  }
  .row td > div {
    width: 3.81cm;
    height: 2.12cm;
    overflow: hidden;

    
  }

 
}

td {
  padding: 0; 
  margin: 0;
  border-width: 1px 1px 0 1px;
  border-color: gray;
  border-style: dotted;
  overflow: hidden;
  box-sizing: border-box;
}

.barcode {
  height: 0.7cm;
  text-align: center;
  overflow: hidden;
}

.model   input, .number  input{
  width: 100%;
  text-align: center;
}

@media print {
  td, input {
    border-color: transparent;
  }
}